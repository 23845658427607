import React from "react";
import "./MenuInfoOld.module.scss";

import { useSelector } from "react-redux";

import styles from "../DraggablePopup/DraggablePopup.module.scss";
import { useAppSelector } from "../../hooks/reduxHooks";

function MTextField({ item, handleTextChange, usrPermission, plcStatus }) {
  return (
    <tr>
      <td>{item.label}</td>
      <td className="s-td">
        <input
          disabled={usrPermission === "r"}
          type="text"
          value={item.value}
          name=""
          min={item.min}
          max={item.max}
          onChange={(event) => handleTextChange(item, event)}
          checked={item.value}
          id=""
        />
        {item["value-label"]}
      </td>
    </tr>
  );
}

function MNumberField({ item, handleTextChange, usrPermission, plcStatus }) {
  return (
    <tr>
      <td>{item.label}</td>
      <td className="s-td">
        <input
          type="number"
          disabled={item.name === "AbsoluteMaximum" || usrPermission === "r"}
          value={item.value}
          name={item.name}
          min={item.min}
          max={item.max}
          style={{ width: "4em" }}
          onChange={(event) => handleTextChange(item, event)}
          checked={item.value}
          id=""
        />
        {item["value-label"]}
      </td>
    </tr>
  );
}

function MSelectField({
  item,
  handleSelectChange,
  usrPermission,
  plcStatus,
  plcDisabled,
}) {
  console.log(plcDisabled);
  return (
    <tr>
      <td>{item.label}</td>
      <td className="s-td">
        <select
          disabled={
            (usrPermission === "r" ||
              (item.name === "Direction" &&
                plcStatus !== "Secure" &&
                plcStatus !== "Active")) &&
            plcDisabled === "false"
          }
          name={item.name}
          id={item.name}
          value={item.value}
          onChange={(event) => handleSelectChange(item, event)}
        >
          {item["dropdown-options"].map((option) => {
            return (
              <option key={option} value={option}>
                {option}
              </option>
            );
          })}
        </select>
      </td>
    </tr>
  );
}

function MSwitch({ item, handleSwitchChange, usrPermission, plcStatus, plcDisabled }) {
  return (
    <tr>
      <td>{item.label}</td>
      <td className="s-td">
        <input
          disabled={
            usrPermission == "r" ||
            (item.name === "TrackStatus" &&
              plcStatus !== "Secure" &&
              plcStatus !== "Active") &&
            plcDisabled === "false"
          }
          type="checkbox"
          name={item.name}
          onChange={(event) => handleSwitchChange(item, event)}
          checked={item.value}
          id=""
        />
        {item["value-label"]}
      </td>
    </tr>
  );
}

function MenuInfo({
  menu: incomingItems,
  handleChange,
  handleSubmit,
  isLoading,
  handleModalOpen,
  resetToOriginal,
  usrPermission,
  config,
}) {
  const {
    id,
    menu,
    "site-level": siteLevelItems,
    "track-level": trackLevelItems,
  } = incomingItems;
  const plcStatus = useSelector((state) => state.plcStatus.status);

  const replaySettings = useAppSelector((state) => state.replay);
  const isReplay = replaySettings.isReplay;
  const plcDisabled = config.plcDisabled;
  return (
    <div className="old-menu-info">
      <span
        onClick={(e) => {
          handleModalOpen(false);
          resetToOriginal();
        }}
        className={styles.closeModal}
      >
        x
      </span>
      <div className="old-menu-info-main">
        {!trackLevelItems && !siteLevelItems && (
          <div className="no-items"> Please select menu </div>
        )}
        {trackLevelItems && siteLevelItems && (
          <div>
            <h3>{menu} Alarm Control</h3>
            <table>
              <tbody>
                {trackLevelItems &&
                  trackLevelItems.map((item) => {
                    if (item.type == "checkbox") {
                      return (
                        <MSwitch
                          usrPermission={usrPermission}
                          plcStatus={plcStatus}
                          key={item.name}
                          item={item}
                          handleSwitchChange={handleChange.bind(
                            this,
                            "track-level",
                            "toggle"
                          )}
                          plcDisabled={plcDisabled}
                        />
                      );
                    }
                    if (item.type == "numbers") {
                      return (
                        <MNumberField
                          usrPermission={usrPermission}
                          plcStatus={plcStatus}
                          key={item.name}
                          item={item}
                          handleTextChange={handleChange.bind(
                            this,
                            "track-level",
                            "number"
                          )}
                        />
                      );
                    }
                    if (item.type == "dropdown") {
                      return (
                        <MSelectField
                          usrPermission={usrPermission}
                          plcStatus={plcStatus}
                          key={item.name}
                          item={item}
                          handleSelectChange={handleChange.bind(
                            this,
                            "track-level",
                            "select"
                          )}
                          plcDisabled={plcDisabled}
                        />
                      );
                    }
                    return null;
                  })}

                {siteLevelItems &&
                  siteLevelItems.map((item) => {
                    if (item.type == "checkbox") {
                      return (
                        <MSwitch
                          usrPermission={usrPermission}
                          key={item.name}
                          item={item}
                          handleSwitchChange={handleChange.bind(
                            this,
                            "site-level",
                            "toggle"
                          )}
                        />
                      );
                    }
                    if (item.type == "numbers") {
                      return (
                        <MNumberField
                          usrPermission={usrPermission}
                          key={item.name}
                          item={item}
                          handleTextChange={handleChange.bind(
                            this,
                            "site-level",
                            "number"
                          )}
                        />
                      );
                    }
                    if (item.type == "dropdown") {
                      return (
                        <MSelectField
                          usrPermission={usrPermission}
                          key={item.name}
                          item={item}
                          handleSelectChange={handleChange.bind(
                            this,
                            "site-level",
                            "select"
                          )}
                        />
                      );
                    }
                    return null;
                  })}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div className="menu-info-button">
        {trackLevelItems && siteLevelItems && (
          <button
            disabled={usrPermission == "r" || isLoading || isReplay}
            onClick={handleSubmit}
            margin="normal"
            variant="contained"
          >
            Update
          </button>
        )}
      </div>
    </div>
  );
}

export default MenuInfo;
