import React from "react";

export default function useFullscreenStatus(
  elRef: React.RefObject<HTMLElement>
): [boolean, () => void] {
  const [isFullscreen, setIsFullscreen] = React.useState(
    Boolean(document.fullscreenElement)
  );

  const setFullscreen = () => {
    if (elRef.current == null) return;

    elRef.current
      .requestFullscreen()
      .then(() => {
        setIsFullscreen(Boolean(document.fullscreenElement));
      })
      .catch(() => {
        setIsFullscreen(false);
      });
  };

  React.useLayoutEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(Boolean(document.fullscreenElement));
    };

    document.onfullscreenchange = handleFullscreenChange;

    return () => {
      document.onfullscreenchange = null;
    };
  }, []);

  return [isFullscreen, setFullscreen];
}

function getBrowserFullscreenElementProp() {
  if ("fullscreenElement" in document) {
    return "fullscreenElement";
  } else if ("webkitFullscreenElement" in document) {
    return "webkitFullscreenElement";
  } else if ("mozFullScreenElement" in document) {
    return "mozFullScreenElement";
  } else if ("msFullscreenElement" in document) {
    return "msFullscreenElement";
  } else {
    throw new Error("fullscreenElement is not supported by this browser");
  }
}
