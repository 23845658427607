import React, { useState, useMemo } from "react";
import { IconButton, Tooltip, Typography } from "@mui/material";
import {
  Circle,
  Error,
  ReportProblem as ReportProblemIcon,
  RadioButtonChecked,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useInterval } from "../../hooks/useInterval";
import { Status, ServiceStatus } from "./ServicesStatus";

function StatusButton(props) {
  const [style, setStyle] = useState(true);
  const style1 = { color: "#29f032", transform: "scale(1.5)" };
  const style2 = { color: "#0ec416", transform: "scale(1.5)" };
  let useStyle = style ? style1 : style2;

  useInterval(() => setStyle(!style), [1000]);

  const criticalServiceNames = [
    "vmonitor",
    "vmonitor-client",
    "tile38",
    "tile38follower",
    "geoevent",
    "geoevent2",
    "redis",
    "postgres",
    "caddy",
    "config-editor",
  ].map((item) => "vmonitor_" + item);

  const dockerStatuses = props.dockerStatuses;
  const criticalServices = dockerStatuses?.filter((service) =>
    criticalServiceNames.includes(service.service_name)
  );
  const dockerOkay = !criticalServices?.some((service) => service._value === 0);

  const status = useSelector((state) => state.status);
  const [startTime, setStartTime] = useState(Date.now());
  const [endTime, setEndTime] = useState(Date.now());

  useMemo(() => {
    if (status.websocket) setStartTime(Date.now());
    else setEndTime(Date.now());
  }, [status.websocket]);

  const warningServices = status.services.filter(
    (task) => task.status === Status.Warn
  );
  const failedServices = status.services.filter(
    (task) => task.status === Status.Fail
  );
  const failedCriticalServices = status.services
    .filter((task) => criticalServiceNames.includes(task.name))
    .filter((task) => task.status === Status.Fail);

  const nodesWarn = status.nodes.down.length > 0;
  const nodesFail = status.nodes.up.length <= status.nodes.down.length;
  const totalNodes = status.nodes.up.length + status.nodes.down.length;
  const nodeFetchError = status.nodes.down.some((node) => node === "error");

  const tooltipLines = [];
  if (!dockerOkay) tooltipLines.push("Error: Critical service failure");
  if (!status.websocket) tooltipLines.push("Error: Websocket failed");
  if (!status.webui) tooltipLines.push("Error: Web UI failed");
  if (warningServices.length > 0 && !failedServices)
    tooltipLines.push(
      `Warning: ${warningServices.length} services running at reduced capacity`
    );
  if (failedServices.length > 0)
    tooltipLines.push(`Error: ${failedServices.length} failed services`);
  if (nodesWarn && !nodesFail && !nodeFetchError)
    tooltipLines.push(
      `Warning: ${status.nodes.down.length}/${totalNodes} Docker nodes have failed`
    );
  if (nodesFail && !nodeFetchError)
    tooltipLines.push(
      `Error: ${status.nodes.down.length}/${totalNodes} Docker nodes have failed`
    );
  if (nodeFetchError)
    tooltipLines.push("Error: Failed to fetch Docker node statuses");

  const tooltip = (
    <>
      {tooltipLines.map((line) => (
        <Typography key={line}>
          {line} <br />
        </Typography>
      ))}
    </>
  );

  if (
    props.status === "OK" &&
    dockerOkay &&
    status.websocket &&
    status.webui &&
    warningServices.length <= 0 &&
    failedServices.length <= 0 &&
    !nodesWarn &&
    !nodesFail
  ) {
    return (
      <Tooltip title={<Typography>No errors reported</Typography>}>
        <IconButton
          style={useStyle}
          onClick={props.onClick}
          aria-label="status-button"
        >
          <RadioButtonChecked />
        </IconButton>
      </Tooltip>
    );
  } else if (
    !status.websocket ||
    !status.webui ||
    failedServices.length > 0 ||
    nodesFail ||
    !dockerOkay ||
    failedCriticalServices.length > 0
  ) {
    const websocketDowntime = !status.websocket ? (
      <Typography>Websocket down for {endTime - startTime} ms</Typography>
    ) : null;
    return (
      <Tooltip
        title={
          <>
            {tooltip}
            {websocketDowntime}
          </>
        }
      >
        <IconButton
          style={{ color: "#d32f2f", transform: "scale(1.5)" }}
          onClick={props.onClick}
          aria-label="status-button"
        >
          <Error />
        </IconButton>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title={tooltip}>
        <IconButton
          style={useStyle}
          onClick={props.onClick}
          aria-label="status-button"
        >
          <ReportProblemIcon style={{ color: "#ff9100" }} />
        </IconButton>
      </Tooltip>
    );
  }
}

export default StatusButton;
