import { useState } from "react";
import { Marker, Popup } from "react-map-gl";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import axios from "axios";
// import { PLC } from '../../../config/config';
import { useSelector } from "react-redux";

export default function BarrierMarker(props) {
  const { barrier, state, config, trackData } = props;
  const [showPopup, setShowPopup] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const lat = parseFloat(barrier.icon[1]);
  const lon = parseFloat(barrier.icon[0]);
  const key = props.name;
  const plc = barrier?.physicalPLC ? barrier.physicalPLC : key;

  //console.log(props.name + ": " + trackData?.status);

  let currstate = "Closed";
  let pendstate = "Open";
  /// /console.log(state);
  if (state !== undefined) {
    currstate = state.state;
    pendstate = state.pendstate;
    if (!pendstate || pendstate === currstate) {
      pendstate = "N/A";
    }
  }

  const barrierOpen = "./barrier-open.png";
  const barrierClosed = "./barrier-closed.png";
  const barrierTransition = "./barrier-pending.gif";
  const barrierDisabled = "./barrier-closed-disabled.png";
  const barrierAlarm = "./barrier-alarm.png";

  let barrierIcon = barrierClosed;

  let status = "";
  if (trackData !== null && trackData !== undefined) {
    status = getBarrierState(
      trackData.status,
      trackData.direction,
      barrier.disableOnTrackClose,
      barrier.directionDisable
    );
  }

  if (status !== "") {
    barrierIcon = barrierDisabled;
  }

  if (currstate === "Open") {
    barrierIcon = barrierOpen;
  }

  if (pendstate !== "N/A") {
    barrierIcon = barrierTransition;
  }

  if (currstate === "Alarm") {
    barrierIcon = barrierAlarm;
  }

  const track = barrier.trackPrefix + " - " + barrier.type;
  const trackView = useSelector((state) => state.currentTrack);
  let trackTest = barrier.trackPrefix === trackView;
  if (trackView === config.sitePrefix) {
    trackTest = true;
  }
  return (
    <>
      {trackTest ? (
        <Marker
          longitude={lon}
          latitude={lat}
          onClick={() => setShowPopup(!showPopup)}
        >
          <img
            title={key + " " + track}
            src={barrierIcon}
            height={"20px"}
            width={"100%"}
          />
        </Marker>
      ) : null}
      {showPopup ? (
        <MarkerPopup
          config={config}
          onClosed={setShowPopup}
          lat={lat}
          lon={lon}
          name={key}
          plc={plc}
          track={track}
          state={currstate}
          isReplay={props.isReplay}
          pendstate={pendstate}
          status={status}
        />
      ) : null}
    </>
  );
}

function MarkerPopup(props) {
  const {
    name,
    plc,
    track,
    lat,
    lon,
    onClosed,
    state,
    pendstate,
    status,
    config,
    isReplay,
  } = props;
  let [showAlert, setShowAlert] = useState(false);

  const plcStatus = useSelector((state) => state.plcStatus.status);

  let disabled = status !== "";

  const permissions = useSelector((state) => state.permissions);

  function forceOpen() {
    sendPLC(plc, "Open", config).then();
    setShowAlert(false);
  }

  return (
    <Popup
      latitude={lat}
      longitude={lon}
      closeOnClick={false}
      onClose={() => onClosed(false)}
    >
      <Stack spacing={1}>
        {disabled ? <h2>{status}</h2> : null}
        <h3>Barrier: {name}</h3>
        <h3>{track}</h3>
        <h3>State: {state}</h3>
        <h3>Pending State: {pendstate}</h3>
        {permissions.canAccessPLC && (
          <>
            <Button
              variant={"contained"}
              disabled={
                disabled ||
                (plcStatus !== "Secure" && plcStatus !== "Active") ||
                state === "Alarm"
              }
              onClick={() => sendPLC(plc, "Open", config)}
            >
              Open
            </Button>
            <Button
              variant={"contained"}
              disabled={
                disabled ||
                (plcStatus !== "Secure" && plcStatus !== "Active") ||
                state === "Alarm"
              }
              onClick={() => sendPLC(plc, "Park", config)}
            >
              Open Park
            </Button>
            <Button
              variant={"contained"}
              disabled={
                (plcStatus !== "Secure" && plcStatus !== "Active") ||
                state === "Alarm"
              }
              onClick={() => sendPLC(plc, "Close", config)}
            >
              Close
            </Button>
            {disabled ? (
              <Button
                variant={"contained"}
                disabled={showAlert}
                onClick={() => setShowAlert(!showAlert)}
              >
                Force Open
              </Button>
            ) : null}
          </>
        )}
      </Stack>
      {showAlert ? (
        <Alert
          severity={"warning"}
          open={showAlert}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Stack spacing={1}>
            <Button variant={"contained"} onClick={() => forceOpen()}>
              Confirm
            </Button>
            <Button variant={"contained"} onClick={() => setShowAlert(false)}>
              Cancel
            </Button>
          </Stack>
        </Alert>
      ) : null}
    </Popup>
  );
}

async function sendPLC(name, action, config) {
  const command = `${config.url.PLC}/plc/` + name + "/" + action;
  console.log("PLC Barrier Command: " + command);
  const response = await axios.get(command);
}

function getBarrierState(
  trackStatus,
  trackDirection,
  disableOnTrackClose,
  directionDisable
) {
  //console.log("Track Status: " + trackStatus);
  //console.log("Disable on Track Close: " + disableOnTrackClose);
  let direction = "";
  if (trackDirection === "CW") {
    direction = "ClockWise";
  }
  if (trackDirection === "CCW") {
    direction = "CounterClockWise";
  }
  if (trackStatus === "Closed" && disableOnTrackClose) {
    return "Disabled";
  }
  if (direction === directionDisable) {
    return "Direction Disabled";
  }
  return "";
}
