import { Marker, useMap } from "react-map-gl";
import * as React from "react";
import { useSelector } from "react-redux";
import { useMemo, useState, useEffect } from "react";

export default function DirectionMarker(props) {
  const {
    latitude,
    longitude,
    rotation,
    direction,
    track,
    visible,
    allTrackViews,
    config
  } = props;

  const lat = parseFloat(latitude);
  const lon = parseFloat(longitude);

  const map = useMap().current;


  let notBoth = true;

  let directionIcon = "./directionMarker.png";
  let directionOffset = 0;
  if (direction === "CounterClockwise") {
     directionOffset = 180
    notBoth = true;
   }
  if(direction === "Clockwise"){
    directionOffset = 0
    notBoth = true;
  }
   if (direction === "Both") {
     directionOffset = 0;
     notBoth = false;
   }



  const trackView = useSelector((state) => state.currentTrack);
  let trackTest = track === trackView;
  if (trackView === config.sitePrefix  || trackView === 'SITE') {
    trackTest = true;
  }

  let dirScale = 1;
  if (trackView === config.sitePrefix) {
    dirScale = 0.75;
  }

  const initialBearing = allTrackViews[trackView]?.bearing || 0;

  const [currentRotation, setCurrentRotation] = useState(
    Number(rotation)
  );

  useEffect(() => {
    if (allTrackViews[trackView]) {
      setCurrentRotation(rotation);
    }
  }, [trackView, allTrackViews, rotation]);

  return (
    <>
      {trackTest && notBoth ? (
        <Marker
          style={{ display: visible ? "inherit" : "none" }}
          longitude={lon}
          latitude={lat}
          rotation={currentRotation + directionOffset}
          rotationAlignment={"map"}
        >
          <img
            src={directionIcon}
            height={45 * dirScale}
            width={45 * dirScale}
            alt="Direction Marker"
          />
        </Marker>
      ) : null}
    </>
  );
}