import React, { useState, useCallback } from "react";
import { Typography, Box } from "@mui/material";
import { calculateTimestamp, toggleControls as toggleReplayControls } from "../../redux/slices/replay";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { useInterval } from "../../hooks/useInterval";

function ReplayInfo() {
  const replayConfig = useAppSelector((state) => state.replay);
  const [timestamp, setLocalTimestamp] = useState(calculateTimestamp(replayConfig));
  const getTimestamp = useCallback(() => calculateTimestamp(replayConfig), [replayConfig]);
  useInterval(() => setLocalTimestamp(getTimestamp()), 10);
  const dispatch = useAppDispatch();
  return (
    <Box>
      <Typography sx={{ cursor: "pointer" }} onClick={() => dispatch(toggleReplayControls(true))}>
        {replayConfig.name}
      </Typography>
      <Typography>{timestamp.toISO()}</Typography>
    </Box>
  );
}

export default ReplayInfo;