import React, { useEffect, useState, useMemo } from "react";
import { Button, Popover, Typography } from "@mui/material";
import { signIn, signOut, useSession } from "next-auth/react";
import { useAppSelector } from "../../hooks/reduxHooks";

function UserInfo(props) {
  const { data } = useSession();
  const config = props.config;
  const username = data?.user?.name;
  const [hover, setHover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const handleOpen = (e) => {
    setHover(true);
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setHover(false);
    setAnchorEl(null);
  };
  const isReplay = useAppSelector((state) => state.replay.isReplay);

  useEffect(() => {
    if (username != null) {
      fetch("/api/getUserInfo").then((info) =>
        info.json().then((user) => setUserInfo(user))
      );
    }
  }, [username]);

  const bannerText = useMemo(() => {
    let banner = "";
    if (isReplay) banner += "Replay Active";
    if (isReplay && config.isQA) banner += " - ";
    if (config.isQA) banner += "QA";
    return banner;
  }, [config, isReplay]);

  if (username == null) {
    return (
      <Button
        variant="contained"
        onClick={() => signIn("LDAP").then((r) => console.log(r))}
      >
        Log In
      </Button>
    );
  }

  const open = Boolean(anchorEl);

  return (
    <div
      aria-owns={open ? "user-info-popover" : undefined}
      aria-haspopup="true"
    >
      <div onMouseEnter={handleOpen} onMouseLeave={handleClose}>
        {hover ? (
          <Button variant={"contained"} onClick={() => signOut()}>
            Log Out
          </Button>
        ) : (
          <>
            {bannerText !== "" && (
              <Typography
                color={"orange"}
                fontStyle={config.isReplay && "italic"}
              >
                {bannerText}
              </Typography>
            )}
            <Typography fontWeight={"bold"}>{username}</Typography>
            <Typography>{userInfo?.permissions?.name}</Typography>
          </>
        )}
      </div>
      <Popover
        id="user-info-popover"
        sx={{ pointerEvents: "none" }}
        open={false}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        onClose={handleClose}
        disableRestoreFocus
      >
        <div>
          {Object.keys(userInfo?.permissions ?? {})
            .filter((value) => value.includes("can"))
            .map((perm) => (
              <Typography key={perm} sx={{ paddingX: 1 }}>
                {perm}: {`${userInfo?.permissions[perm]}`}
              </Typography>
            ))}
          <br />
          {Object.keys(userInfo?.user ?? {}).map((field) => (
            <Typography key={field} sx={{ paddingX: 1 }}>
              {field}: {`${userInfo?.user[field]}`}
            </Typography>
          ))}
        </div>
      </Popover>
    </div>
  );
}

export default UserInfo;
