// src/utils/headerUtils.js
import axios from "axios";

export async function postTrackView(config, view) {
  const post = await axios.post(`${config.url.API}/updateTrackView`, view);
}

export function connectPlcStatusEvent(config, setPlcStatus, updatePlcStatus, store) {
  const plcStatusEvent = new EventSource(`${config.url.PLC}/plc-status-event`);
  plcStatusEvent.onmessage = (e) => {
    let json = JSON.parse(e.data);
    if (typeof json === "string") {
      json = JSON.parse(json);
    }
    let plcState = json?.plcStatus ? json.plcStatus : "Inactive";
    setPlcStatus(plcState);
    store.dispatch(updatePlcStatus({ status: plcState }));
  };
  plcStatusEvent.onerror = (e) => {
    plcStatusEvent.close();
    setTimeout(() => {
      connectPlcStatusEvent(config, setPlcStatus, updatePlcStatus, store);
    }, 1000);
  };
}
