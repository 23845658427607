import { update } from "../Map/Marker/trackerSlice";
import { set as setStatus } from "../../statusSlice";
import { update as updateDirMax } from "../../redux/slices/dirmax";
import { update as updateCategories } from "../../redux/slices/categories";
import { update as updateBarriers } from "../../redux/slices/barriers";
import { update as updatePlcBarriersEvent } from "../../redux/slices/plcBarriersEvent";
import { update as updateReplay } from "../../redux/slices/replay";
import { update as updateLights } from "../../redux/slices/lights";

import store from "../../reduxStore";
import { io } from "socket.io-client";
import { DateTime } from "luxon";
import axios from "axios";

export default function loadWebsocket(config) {
  //   console.log("loading websocket");
  //   console.log(config.url.WEBSOCKET);
  const alarmSound = new Audio("./REDWARNING.wav");
  const barrierSound = new Audio("./BARRIERREQUEST.wav");

  const socket = io(config.url.WEBSOCKET, {
    // transports: ['websocket'],
    timeout: 1000,
    reconnectionDelayMax: 1500,
    forceNew: false,
    reconnectionDelay: 250,
  });
  socket.on("connect", function () {
    if (config?.redundancy) {
      axios
        .get("/api/nodeinfo")
        .then((res) => console.log(res.data))
        .catch();
    }
    //console.log("connected to websocket");
    store.dispatch(setStatus({ websocket: true }));
  });

  //console.log(socket);

  socket.on("disconnect", (reason, description) => {
    if (config?.redundancy) {
      axios
        .get("/api/nodeinfo")
        .then((res) => console.log(res.data))
        .catch();
    }
    //console.log(reason);
    //console.log(description);
    store.dispatch(setStatus({ websocket: false }));
  });
  socket.on("close", (args) => {
    if (config?.redundancy) {
      axios
        .get("/api/nodeinfo")
        .then((res) => console.log(res.data))
        .catch();
    }
    //console.log(args);
    store.dispatch(setStatus({ websocket: false }));
  });
  //non-batch sending
  socket.on("tracker", (data) => {
    const json = JSON.parse(data);
    if (!store.getState().status.websocket) {
      store.dispatch(setStatus({ websocket: true }));
    }
    store.dispatch(update(json));
  });
  socket.on("alarm", (data) => {
    const json = JSON.parse(data);
    if (json.level === "High") {
      //console.log("PLAY SOUND");
      alarmSound.play().then();
    }
  });
  socket.on("barrierRequest", (data) => {
    // play sound
    //console.log(data);
    barrierSound.play().then();
  });
  socket.on("dirmax", (data) => {
    store.dispatch(updateDirMax(JSON.parse(data)));
  });
  socket.on("categories", (data) => {
    store.dispatch(updateCategories(JSON.parse(data)));
  });
  socket.on("barriers", (data) => {
    // {"replay":"active"} is used to notify the backend. using it here will crash the application
    //console.log(data);
    if (data.includes('"replay"')) {
      return;
    }
    store.dispatch(updateBarriers(JSON.parse(data)));
  });
  socket.on("plc-barriers-event", (data) => {
    let json = JSON.parse(data);
    //console.log(json);
    store.dispatch(updatePlcBarriersEvent(json.data));
  });
  socket.on("lights", (data) => {
    //console.log(data);
    let json = JSON.parse(data);
    if (typeof json === "string") {
      json = JSON.parse(json);
    }
    //console.log(json);
    store.dispatch(updateLights(json.items));
  });

  socket.on("replay-response", (data) => {
    const parsed = JSON.parse(data);
    console.log(parsed);
    // console.log(DateTime.fromISO(parsed["timestamp"], {zone: "UTC"}))
    // store.dispatch(togglePlaying(parsed["running"]))
    // const asISOWithoutTimezone = DateTime.fromISO(parsed["timestamp"]).toUTC().toISO({includeOffset: false})
    let timestamp;
    if (typeof parsed["timestamp"] === "number") {
      timestamp = DateTime.fromMillis(parsed["timestamp"]);
    } else {
      timestamp = DateTime.fromISO(parsed["timestamp"]);
    }
    //console.log(timestamp);
    // console.log(DateTime)
    // console.log(DateTime.fromISO(parsed["timestamp"]))
    // console.log(DateTime.fromISO(parsed["timestamp"]).toUTC().toISO({includeOffset: false}))
    // console.log(DateTime.fromISO(parsed["timestamp"], {zone: 'utc'}).toMillis())
    store.dispatch(
      updateReplay({
        running: parsed["running"],
        lastTimestamp: timestamp.toMillis(),
        started: Date.now(),
        currentIMEIs: parsed["imeis"] ?? [],
      })
    );
    axios
      .post("/api/replay/setCachedTimestamp", {
        timestamp: timestamp.toMillis(),
      })
      .catch(console.error);
  });

  //batch sending
  socket.on("array", (data) => {
    // console.log("got tracker array")
    data.messages.forEach((message) => {
      const parsedMessage = { ...JSON.parse(message), time: Date.now() };
      //console.log(parsedMessage);
      if (!!parsedMessage?.Latitude && !!parsedMessage?.Longitude) {
        store.dispatch(update(parsedMessage));
      } else {
        console.warn(
          "Message does not contain a valid latitude and longitude: ",
          parsedMessage
        );
      }
    });
    // console.log(store.getState().status.websocket);
    if (!store.getState().status.websocket) {
      //console.log("updating websocket status");
      store.dispatch(setStatus({ websocket: true }));
    }
    //console.log(data.messages);
  });

  socket.on("replay-peer", (data) => {
    const local = store.getState().replay;
    const remote = JSON.parse(data);
    console.log("replay-peer", remote);
    console.log(remote.clientID);
    console.log(local.clientID);
    if (
      remote.clientID !== local.clientID &&
      (remote.isReplay || remote.isReplay !== local.isReplay)
    ) {
      store.dispatch(
        updateReplay({
          ...remote,
          clientID: local.clientID,
          isFromRemote: true,
        })
      );
    }
  });

  return socket;
}
